<template>
  <div class="content">
    <div class="Top">
      <p>提交成功</p>
      <p>我们将尽快为您配送，请在收到卡后的10天内激活使用，过期将会回收哦！</p>
      <img
        src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-01/11/yuelvhui9D9pEJCnWZ1606792477.jpeg"
        alt=""
      >
      <p>关注"<span>王卡助手</span>"微信公众号，享受更多王卡特权</p>
    </div>
    <div class="bottom">
      <div class="BenefitsText">
        福利推荐
        <div class="BenefitsText1">激活成功后，在悦淘APP领取福利</div>
        <img :src="RightsUrl" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubmitSuccess',
  data() {
    return {
      RightsUrl: ''
    }
  },
  created() {
    this.cur = this.$route.query ? Number(this.$route.query.cur) : Number(this.$route.query.cur)// 0 大王卡 1 5G卡
    if (this.cur === 0) {
      this.RightsUrl = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/17/yuelvhui9NOYTCmxaT1607074293.png'
    } else if (this.cur === 1) {
      this.RightsUrl = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/17/yuelvhuiR01NNUtAD91607074248.png'
    }
  },
  mounted() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #fff;
  font-family: "PingFang HK";
}

.content {
  position: absolute;
  text-align: center;

  .Top {
    text-align: center;
    background-color: #F7F7F7;
    padding: 40px 20px 20px;

    p {
      margin-bottom: 16px;
    }

    p:nth-child(1) {
      color: #E33F44;
      font-size: 24px;
      font-weight: 700;
    }

    p:nth-child(2) {
      color: #333;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    p:nth-child(4) {
      color: #666;
      font-size: 14px;
      margin-top: 18px;

      span {
        color: #E33F44;
      }
    }

    img {
      width: 100px;
      height: 100px;

    }
  }

  .bottom {
    text-align: center;

    .BenefitsText {
      width: 100%;
      height: 100px;
      color: #E33F44;
      font-size: 24px;
      padding: 15px 15px 10px 15px;
      font-weight: 700;

      .BenefitsText1 {
        width: 100%;
        margin-top: 13px;
        font-size: 16px;
        color: #333;
        font-weight: 400;
      }

      img {
        margin-top: 20px;
        vertical-align: top;
      }
    }
  }
}
</style>
